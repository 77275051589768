.wrapper{
    width: 100vw;
    overflow: hidden;
    margin: 0;
    padding: 0;
  }
  .p-wrapper {
    position: relative;
    height: 14vh;
    width:100vw;
    margin: 0;
    white-space: nowrap;
    color: #A6DED0;
    overflow:hidden; 
    text-align:center ;
    font-family: "SpaceGrotesk";
    font-size: 6em;

  }
  .font-size-mobile {
    font-size: 3em;
    height: 8vh;
 }
  /* go left starts here */
  .left-1 {
    position: absolute;
    left: 100vw;
    display:inline-block;
    -webkit-animation: go-left-1 40s linear infinite;
    animation: go-left-1 40s linear infinite;
  }
  .left-2 {
    position: absolute;
    left: 100vw;
    display:inline-block;
    -webkit-animation: go-left-2 40s linear infinite;
    animation: go-left-2 40s linear infinite;
    animation-delay: 20s;
  }
  

  @keyframes go-left-1 {
      0%  { transform: translateX(0%);
            -webkit-transform: translateX(0%);
            visibility: visible;
        }
    100%  { transform: translateX(-200%);
            -webkit-transform: translateX(-200%);
            visibility: hidden;
        }
  }
@-webkit-keyframes go-left-1 {
      0% {  -webkit-transform: translateX(0%); visibility: visible; }
    100% {  -webkit-transform: translateX(-200%); visibility: hidden;}
}
  
@keyframes go-left-2 {
      0% {  
          visibility: visible;
          transform: translateX(0%);
          -webkit-transform: translateX(0%);
        }
    100% {
          visibility: hidden;
          transform: translateX(-200%);
          -webkit-transform: translateX(-200%);
        }
  }
@-webkit-keyframes go-left-2 {
      0% {  -webkit-transform: translateX(0%); visibility: visible; }
    100% {  -webkit-transform: translateX(-200%); visibility: hidden;}
}

/*go right starts here*/
  
.right-1 {
  display:inline-block;
  position: absolute;
  right: 100vw;
  -webkit-animation: go-right-1 40s linear infinite;
  animation: go-right-1 40s linear infinite;

}
.right-2 {
  position: absolute;
  right: 100vw;
  display:inline-block;
  -webkit-animation: go-right-2 40s linear infinite;
  animation: go-right-2 40s linear infinite;
  animation-delay: 20s;
}


@keyframes go-right-1 {
    0%  { transform: translateX(0%);
          -webkit-transform: translateX(0%);
          visibility: visible;
 
      }
  100%  { transform: translateX(200%);
          -webkit-transform: translateX(200%);
          visibility: hidden;
      }
}
@-webkit-keyframes go-right-1 {
    0% {  -webkit-transform: translateX(0%);  visibility: visible;}
  100% {  -webkit-transform: translateX(200%); visibility: hidden;}
}

@keyframes go-right-2 {
    0% {  
        transform: translateX(0%);
        -webkit-transform: translateX(0%);
        visibility: visible;
      }
  100% {
        visibility: hidden;
        transform: translateX(200%);
        -webkit-transform: translateX(200%);
      }
}
@-webkit-keyframes go-right-2 {
    0% {  -webkit-transform: translateX(0%); visibility: visible; }
  100% {  -webkit-transform: translateX(200%); visibility: hidden;}
}