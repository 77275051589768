select, input {
  border:1px solid #334155;
}


select:hover, input:hover {
  border: 1px solid #00DEA6;
}

select:focus, input:focus{
  outline: 2.8px solid rgba(147, 197, 253, 0.5);
  border:1px solid #00DEA6;
}

input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus, 
input:-webkit-autofill:active{
    -webkit-box-shadow: 0 0 0 30px rgb(22, 26, 25) inset !important;
    -webkit-text-fill-color: #FFFFFFBF;
}

select:focus:hover, input:focus:hover{
  outline: 2.8px solid rgba(147, 197, 253, 0.5);
  border:1px solid #00DEA6;
}
