.base-star{
    -webkit-animation: move-star ease-in infinite;
    animation: move-star ease-in infinite;
}
@keyframes move-star {
    0% {
        opacity: 0;
        -webkit-transform: scale(0) translateX(0) translateY(0);
        -ms-transform: scale(0) translateX(0) translateY(0);
        transform: scale(0) translateX(0) translateY(0);
    }
    50% {
        opacity: 1;
    }
    100% {
        opacity: 0;
        -webkit-transform: scale(1) translateX(300%) translateY(300%);
        -ms-transform: scale(1) translateX(300%) translateY(300%);
        transform: scale(1) translateX(300%) translateY(300%);
    }
}
@-webkit-keyframes move-star {
    0% {
        opacity: 0;
        -webkit-transform: scale(0) translateX(0) translateY(0);
        -ms-transform: scale(0) translateX(0) translateY(0);
        transform: scale(0) translateX(0) translateY(0);
    }
    50% {
        opacity: 1;
    }
    100% {
        opacity: 0;
        -webkit-transform: scale(1) translateX(300%) translateY(300%);
        -ms-transform: scale(1) translateX(300%) translateY(300%);
        transform: scale(1) translateX(300%) translateY(300%);
    }
}
