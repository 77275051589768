@font-face {
  font-family: "SpaceGrotesk";
  src: local("SpaceGrotesk"),
   url("./assets/fonts/SpaceGrotesk-Regular.ttf") format("truetype");
  font-weight: bold;
} 

@font-face {
  font-family: "SpaceGrotesk-Medium";
  src: local("SpaceGrotesk-Medium"),
   url("./assets/fonts/SpaceGrotesk-Medium.ttf") format("truetype");
  /* font-weight: bold; */
}

@font-face {
  font-family: "SpaceGrotesk-SemiBold";
  src: local("SpaceGrotesk-SemiBold"),
   url("./assets/fonts/SpaceGrotesk-SemiBold.ttf") format("truetype");
  /* font-weight: bold; */
}

@font-face {
  font-family: "Inter";
  src: local("Inter"),
   url("./assets/fonts/Inter-Regular.otf") format("truetype");
  font-weight: bold;
}

body {
  background-color: black;
  width: 100vw;
  box-sizing: border-box;
  cursor: url(DefaultCursor.svg) 10 10, auto;
  overflow: scroll;
  overflow-x: hidden;
}

a:hover {
  cursor: url(XCursor.svg) 10 10, auto;
}

button:hover {
  cursor: url(XCursor.svg) 10 10, auto;
}

::-webkit-scrollbar {
  width: 0; 
  background: transparent;  
}
.animatedBackground{
  background: linear-gradient(to right, #00DEA6 50%, #161A19 50%);
  background-size: 250% 100%;
  background-position: right bottom;
  background-attachment: fixed;
  -webkit-transition: all  0.3s;
	-moz-transition: all 0.3s;
	transition: all 0.3s ease-out;
}

#live{
  background: linear-gradient(to right, #00DEA6 50%, #FFFFFF1A 50%);
  background-size: 250% 100%;
  background-position: right bottom;
  background-attachment: fixed;
  -webkit-transition: all  0.3s;
	-moz-transition: all 0.3s;
	transition: all 0.3s ease-out;
  color: #FFFFFFBF;
}

.animatedBackground:hover, .underlinedText:hover .underline {
  background-position:left bottom;
}

#live:hover {
  background-position:left bottom;
  color: #0A0D0C
}

.underline{
  height:2px;
  -webkit-transition: all  0.3s;
	-moz-transition: all 0.3s;
	transition: all 0.3s ease-out;
  background: linear-gradient(to right, #A7DED0 50%, rgba(255, 255, 255, 0.1) 50%);
  background-size: 250% 100%;
  background-position: right bottom;
  margin-top: 1vh;
}

@-webkit-keyframes opacity {
  0%   { opacity: 0.2; }
  50% { opacity: 1; }
  100%   { opacity: 0.2; }
}
@-moz-keyframes opacity {
  0%   { opacity: 0.2; }
  50% { opacity: 1; }
  100%   { opacity: 0.2; }
}
@-o-keyframes opacity {
  0%   { opacity: 0.2; }
  50% { opacity: 1; }
  100%   { opacity: 0.2; }
}
@keyframes opacity {
  0%   { opacity: 0.2; }
  50% { opacity: 1; }
  100%   { opacity: 0.2; }
}

#blink {
  height: 8px;
  width: 8px;
  background-color: #FFFFFFBF;
  border-radius: 50%;
  display: inline-block;
  -webkit-animation: opacity 1s infinite; /* Safari 4+ */
  -moz-animation:    opacity 1s infinite; /* Fx 5+ */
  -o-animation:      opacity 1s infinite; /* Opera 12+ */
  animation:         opacity 1s infinite; /* IE 10+, Fx 29+ */
  margin-right: 10px;
  vertical-align:baseline;
}

@-webkit-keyframes blink {
  0%   { opacity: 0; }
  50% { opacity: 1; }
  100%   { opacity: 1; }
}
@-moz-keyframes blink {
  0%   { opacity: 0; }
  50% { opacity: 1; }
  100%   { opacity: 1; }
}
@-o-keyframes blink {
  0%   { opacity: 0; }
  50% { opacity: 1; }
  100%   { opacity: 1; }
}
@keyframes blink {
  0%   { opacity: 0; }
  50% { opacity: 1; }
  100%   { opacity: 1; }
}

#footer-animation {
  -webkit-animation: blink 1s infinite; /* Safari 4+ */
  -moz-animation:   blink 1s infinite; /* Fx 5+ */
  -o-animation:     blink 1s infinite; /* Opera 12+ */
  animation:        blink 1s infinite; /* IE 10+, Fx 29+ */
}

@-webkit-keyframes easyBlink {
  0%   { opacity: 0.8; }
  40% { opacity: 1; }
  80% { opacity: 0.8; }
  100%   { opacity: 1; }
}
@-moz-keyframes easyBlink {
  0%   { opacity: 0.8; }
  40% { opacity: 1; }
  80% { opacity: 0.8; }
  100%   { opacity: 1; }
}
@-o-keyframes easyBlink {
  0%   { opacity: 0.8; }
  40% { opacity: 1; }
  80% { opacity: 0.8; }
  100%   { opacity: 1; }
}
@keyframes easyBlink {
  0%   { opacity: 0.8; }
  40% { opacity: 1; }
  80% { opacity: 0.8; }
  100%   { opacity: 1; }
}

.footer-time {
  -webkit-animation: easyBlink 1s infinite; /* Safari 4+ */
  -moz-animation:   easyBlink 1s infinite; /* Fx 5+ */
  -o-animation:    easyBlink 1s infinite; /* Opera 12+ */
  animation:       easyBlink 1s infinite; /* IE 10+, Fx 29+ */
}

@media only screen and (max-width: 768px) {
  .underline{
    height:2px;
    -webkit-transition: none;
    -moz-transition: none;
    transition: none;
    background: #A7DED0;
    background-size:100%;
    background-position: center;
  }
}
