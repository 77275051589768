.logo-desktop{
    position: fixed;
    right: 9vw; 
    top: 60vh;
    -webkit-transition: all 3s ease-out;
    -moz-transition: all 3s ease-out;
    -ms-transition: all 3s ease-out;
    -o-transizion: all 3s ease-out;
    transition: all 3s ease-out;
    -webkit-animation: rotate 20s infinite linear;
    -moz-animation: rotate 20s infinite linear;
    -ms-animation: rotate 20s infinite linear;
    -o-animation: rotate 20s infinite linear;
    animation: rotate 20s infinite linear;
    z-index: 2;

}
.logo-mobile{
    position: absolute;
    top: 2vh;
    right: 5.57vw; 
    -webkit-animation: rotate 20s infinite linear;
    -moz-animation: rotate 20s infinite linear;
    -ms-animation: rotate 20s infinite linear;
    -o-animation: rotate 20s infinite linear;
    animation: rotate 20s infinite linear;
}
.logo-move{
    top: 10vh;
}

@keyframes rotate{
    100%{
        -webkit-transform: rotate(360deg);
        -moz-transform: rotate(360deg);
        -ms-transform: rotate(360deg);
        -o-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}
@-webkit-keyframes rotate {
    100%{
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}
@-moz-keyframes rotate {
    100%{
        -moz-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}
@-ms-keyframes rotate{
    100%{
        -ms-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}
@-o-keyframes rotate{
    100%{
        -o-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}