@keyframes slideInLeft {
  0% {
    transform: translateX(-100%);
    opacity: 0;
  }
  50% {
    transform: translateX(-50%);
    opacity: 0.5;
  }
  100% {
    transform: translateX(0%);
    opacity: 1;
  }
}

/* desktop: */
@keyframes expandHeight {
  0% {
    min-height: 148px;
    padding: 0 0;
  }
  100% {
    min-height: 200px;
    padding: 5% 0;
  }
}
/* mobile: */
@keyframes expandHeightMobile {
  0% {
    min-height:52px;
    padding: 0 0;
  }
  100% {
    min-height: 200px;
    padding: 5% 0;
  }
}
@keyframes collapseHeight {
  0% {
    height: 100%;
  }
  100% {
    height: 0;
  }
}

