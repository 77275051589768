#blinkInfo {
  height: 8px;
  width: 8px;
  background-color: rgba(10, 13, 12, 1);
  border-radius: 50%;
  display: inline-block;
  -webkit-animation: opacity 1s infinite; /* Safari 4+ */
  -moz-animation:    opacity 1s infinite; /* Fx 5+ */
  -o-animation:      opacity 1s infinite; /* Opera 12+ */
  animation:         opacity 1s infinite; /* IE 10+, Fx 29+ */
  margin-left: 10px;
  vertical-align:baseline;
}